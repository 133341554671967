<template>
  <RecordViewWrapper>
    <sdPageHeader title="Suppliers Quotations" class="custom-header-full">
      <template #subTitle>
        <sdButton class="btn-add_new" size="default" type="primary">
          <router-link to="/purchase/deal">
            <sdFeatherIcons type="plus" size="14" /> Add new
          </router-link>
        </sdButton>
         &nbsp;&nbsp;&nbsp;
        <sdButton @click="switchDeletedView" size="default">
          <sdFeatherIcons v-if="!deletedItems" type="trash" size="14" />
          {{ deletedItems ? "Current" : "Deleted" }} Quotations
        </sdButton>
      </template>
    </sdPageHeader>
    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <GlobalFilters @onFilter="filter"></GlobalFilters>
        </a-col>
      </a-row>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table :loading="isLoading" :dataSource="dataSource" :columns="columns" :pagination="pagination"
                  :pageSize="pagination.pageSize" showSizeChanger />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>
<script>
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { Main, TableWrapper } from "../styled";
import { useStore } from "vuex";
import { getCurrentInstance, defineAsyncComponent, computed, onMounted, ref, reactive } from "vue";
import moment from "moment";

const GlobalFilters = defineAsyncComponent(() => import("@/components/businessComponents/GlobalFilters.vue"));

const columns = [
  {
    title: "Partner",
    dataIndex: "deal.partenaireName",
    key: "deal.partenaireName",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Validity",
    dataIndex: "validityDate",
    key: "validityDate",
  },
  {
    title: "Number of products",
    dataIndex: "deal.numberOfProduct",
    key: "deal.numberOfProduct",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const ViewPage = {
  name: "ViewPage",
  components: { GlobalFilters, RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const DealApi = computed(() => state.dealApi.data);
    const isLoading = computed(() => state.dealApi.loading);
    const selectedRowKeys = ref([]);
    const pagination = reactive({
      dealPartenaire: "",
      DealDate: ""
    });
    const deletedItems = ref(false);

    const appContext = getCurrentInstance().appContext;
    const userHasPermission = appContext.config.globalProperties.$userHasPermission;

    onMounted(() => {
      dispatch("dealGetAllData", pagination);
    });

    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("dealApiDataDelete", id);
      }
      return false;
    };
    const restorItem = (id) => {
      const confirm = window.confirm("Are you sure restore this?");
      if (confirm) {
        dispatch("dealApiDataRestore", id);
      }
      return false;
    };

    const formState = reactive({
      searchItem: "",
    });

    const onHandleSearch = () => {
      dispatch("contractDataSearch", formState.searchItem);
    };

    const dataSource = computed(() =>
      DealApi.value && DealApi.value.length
        ? DealApi.value.map((deal, key) => {
          return {
            key: key + 1,
            deal,
            date: <div class="">{moment(deal.date).format("DD/MM/YYYY")}</div>,
            validityDate: (
              <div class="">
                {moment(deal.date)
                  .clone()
                  .add(deal.validityDate, "days")
                  .format("DD/MM/YYYY")}
              </div>
            ),
            action: generateDealActions(deal),
          };
        })
        : []
    );

    const filter = (filters) => {
      pagination.dealPartenaire = filters.partnerId ?? "";
      pagination.DealDate = filters.date ? moment(filters.date).format('YYYY/MM/DD') : "";

      if (deletedItems.value) {
        dispatch("dealGetAllDeletedData", { pagination });
      } else {
        dispatch("dealGetAllData", { pagination });
      }
    }

     const generateDealActions = (deal) => {
      if (!deletedItems.value) {
        
        return (
          <div class="table-actions">
          <router-link
            class="edit"
            to={"/purchase/edit-deal/" + deal.id}
          >
            <sdFeatherIcons type="eye" size={14} />
          </router-link>
          &nbsp;&nbsp;&nbsp;
          {userHasPermission('Permissions.Deal.Delete') && (
            <a-button class="delete" onClick={() => handleDelete(deal)}>
              <sdFeatherIcons type="trash-2" size={14} />
            </a-button>
          )}
        </div>
      );
    }else{
        return (
          <div class="table-actions">
           <a-button class="delete" onClick={() => restorItem(deal)}>
              <sdFeatherIcons type="refresh-ccw" size={14} />
            </a-button>
          </div>)
    }
    };


    // Pagination Logic
    const switchDeletedView = () => {
      deletedItems.value = !deletedItems.value;
      pagination.dealPartenaire = "";
      pagination.dealDate = "";
      if (deletedItems.value) {
        dispatch("dealGetAllDeletedData",  pagination );
      } else {
        dispatch("dealGetAllData",  pagination );
      }
    };


    return {
      generateDealActions,
      switchDeletedView,
deletedItems,
      filter,
      pagination,
      DealApi,
      isLoading,
      selectedRowKeys,
      onHandleSearch,
      handleDelete,
      dataSource,
      columns,
      formState,
    };
  },
};

export default ViewPage;
</script>
